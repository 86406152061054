html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  overflow-y: scroll;
}

body {
  font-family: $font_bold;
  margin: 0;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'kern';
  font-kerning: normal;
  text-rendering: optimizeLegibility;

  &.gallery-is-open {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
}

#container {
  padding: 15px get-vw(15px) 0;
  position: relative;

  @include breakpoint-down {
    padding: 0;
  }
}

.clear-fix {
  &:after {
    content: "";
    float: none;
    clear: both;
    display: block;
    width: 100%;
  }
}

.float {
  & > div, & > ul {
    float: left;
  }
}

.heading {
  margin-bottom: .1em;
}

a {
  text-decoration: none;
  color: inherit;
  outline: 0;
}

p {
  margin-bottom: 1.14em;
  word-spacing: -.04em;

  a {
    text-decoration: underline;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

.about-info {
  ul {
    margin-bottom: 1.14em;
    word-spacing: -.04em;
  }
}

.iframe-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  cursor: pointer;
  display: block;

  iframe, .iframe-poster {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .iframe-poster {
    z-index: 1;
    cursor: pointer;
  }
}

.play-button {
  position: relative;

  body:not(.gallery-is-open) &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    width: get-vw(60px);
    height: get-vw(60px);
    background: #FFF url("../../img/ui/play-icon.svg") no-repeat center center;
    background-size: 100% 100%;
    transform: translate(-50%, -50%);
    border-radius: 100%;

    @include breakpoint-down {
      width: get-vw(180px);
      height: get-vw(180px);
    }
  }
}